import { render, staticRenderFns } from "./preferResolution.vue?vue&type=template&id=06376259&scoped=true"
import script from "./preferResolution.vue?vue&type=script&lang=js"
export * from "./preferResolution.vue?vue&type=script&lang=js"
import style0 from "./preferResolution.vue?vue&type=style&index=0&id=06376259&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06376259",
  null
  
)

export default component.exports