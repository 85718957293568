<template>
  <!-- 提供新的说明解法 -->
  <div class="cson-bg">
    <div class="perfer">
      <div class="container">
        <div class="title">提供新的解法及说明</div>
        <!--        <div class="description">-->
        <!--          提供新的解法和说明，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定）-->
        <!--        </div>-->
        <el-form ref="form" :model="form" label-position="top" :rules="rules">
          <el-form-item label="解法说明：" prop="idea">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 20 }"
              v-model="form.idea"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码：" prop="code">
            <div id="myWangEditorCode">
              <Toolbar
                style="
                  border: 1px solid #dcdfe6;
                  border-bottom: none;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                "
                :editor="editorId"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="
                  height: 150px;
                  border: 1px solid #dcdfe6;
                  border-top: none;
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                "
                :editor="editorId"
                :defaultConfig="editorConfig"
                :defaultContent="getDefaultContent"
                :mode="mode"
                @onChange="onChange"
                @onCreated="onCreated"
              />
            </div>
          </el-form-item>
        </el-form>
        <div style="padding: 20px; text-align: center">
          <el-button
            type="primary"
            style="width: 130px"
            size="mini"
            @click="publishResolution"
            >保存发表</el-button
          >
        </div>
      </div>
      <div class="sideadvertisement">
        <SideAdvertisement></SideAdvertisement>
      </div>
    </div>
  </div>
</template>
<script>
import SideAdvertisement from "@/components/sideAdvertisement";
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import cloneDeep from "lodash.clonedeep";
import { mapState } from "vuex";
import { publishResolution } from "@/service/leetcode";
export default {
  name: "perferResolution",
  components: { SideAdvertisement, Editor, Toolbar },
  data() {
    return {
      codeInfo: {},
      form: {},
      editorId: null,
      toolbarConfig: {
        toolbarKeys: [
          "codeBlock",
          "|",
          "bold",
          "bulletedList",
          "numberedList",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-indent",
            title: "缩进",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
          },
          "|",
          "emotion",
          {
            key: "group-image",
            title: "图片",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"],
          },
          "|",
          "redo",
          "undo",
          "|",
          "fullScreen",
        ],
      },
      defaultContent: [],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server:
              process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
            customInsert(res, insertFn) {
              insertFn(res.result.accessibleUrl);
            },
            headers: {
              "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
            },
            maxFileSize: 5 * 1024 * 1024,
          },
          codeSelectLang: {
            codeLangs: [
              { text: "Javascript", value: "javascript" },
              { text: "Java", value: "java" },
              { text: "Python", value: "python" },
              { text: "PHP", value: "php" },
              { text: "C++", value: "cpp" },
              { text: "C", value: "c" },
            ],
          },
        },
      },
      mode: "default",
      curContent: [],
      rules: {
        idea: [
          {
            required: true,
            message: "请输入解法说明！",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入代码！",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    onChange(editor) {
      this.curContent = editor.children;
      this.form.code = editor.getHtml();
    },
    onCreated(editor) {
      this.editorId = Object.seal(editor);
    },
    publishResolution() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          publishResolution({
            ...this.form,
            leetcodeProblemId: this.$route.query.id,
          }).then((res) => {
            if (res.success) {
              this.$message.success("发布成功，请等待审核！");
              this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    getDefaultContent() {
      return cloneDeep(this.defaultContent);
    },
  },
};
</script>
<style lang="scss" scoped>
.cson-bg {
  background-color: #f4f4f4;
}
.perfer {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  .container {
    width: 880px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 50px;
    .title {
      position: relative;
      font-weight: 500;
      color: #34495e;
      font-size: 16px;
      padding-left: 14px;
      line-height: 24px;
      padding-bottom: 12px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 24px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 0;
      }
    }
    .description {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
    }
  }
  .sideadvertisement {
    width: 300px;
  }
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
